<template>
	<div>
		<el-form ref="form" :rules="rules" :model="form" label-position="top">
			<div class="row">
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="Problemas musculares, articulares, hernia…" prop="health_question_1">
						<el-radio-group v-model="form.health_question_1">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>

						<template v-if="form.health_question_1 === true">
							<el-input type="textarea" v-model="form.health_text_1"></el-input>
						</template>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="Problemas del corazón" prop="health_question_2">
						<el-radio-group v-model="form.health_question_2">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>

						<template v-if="form.health_question_2 === true">
							<el-input type="textarea" v-model="form.health_text_2"></el-input>
						</template>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="¿Nota dolor en el pecho cuando realiza alguna act.fís. o reposo?" prop="health_question_3">
						<el-radio-group v-model="form.health_question_3">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>

						<template v-if="form.health_question_3 === true">
							<el-input type="textarea" v-model="form.health_text_3"></el-input>
						</template>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="Hipertensión" prop="health_question_4">
						<el-radio-group v-model="form.health_question_4">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>

						<template v-if="form.health_question_4 === true">
							<el-input type="textarea" v-model="form.health_text_4"></el-input>
						</template>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="Hipercolesterolemia" prop="health_question_5">
						<el-radio-group v-model="form.health_question_5">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>

						<template v-if="form.health_question_5 === true">
							<el-input type="textarea" v-model="form.health_text_5"></el-input>
						</template>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="Celiaco" prop="health_question_6">
						<el-radio-group v-model="form.health_question_6">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>

						<template v-if="form.health_question_6 === true">
							<el-input type="textarea" v-model="form.health_text_6"></el-input>
						</template>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="Operaciones reseñables" prop="health_question_7">
						<el-radio-group v-model="form.health_question_7">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>

						<template v-if="form.health_question_7 === true">
							<el-input type="textarea" v-model="form.health_text_7"></el-input>
						</template>
					</el-form-item>
				</div>
			</div>
		</el-form>

		<div class="row justify-content-center pt-4 mt-4 border-top">
			<div class="col-10 col-md-6 col-lg-4">
				<el-button class="btn-block" @click="next('form')" type="info" plain>Next step</el-button>
			</div>
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				form: {
					health_question_1:'',
					health_text_1:'',
					health_question_2:'',
					health_text_2:'',
					health_question_3:'',
					health_text_3:'',
					health_question_4:'',
					health_text_4:'',
					health_question_5:'',
					health_text_5:'',
					health_question_6:'',
					health_text_6:'',
					health_question_7:'',
					health_text_7:'',
				},
				rules: {
					health_question_1: [
						{ required: true, message: 'Campo requerido', trigger: 'change' },
					],
					health_question_2: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					],
					health_question_3: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					],
					health_question_4: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					],
					health_question_5: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					],
					health_question_6: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					],
					health_question_7: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					]
				}

			}
		},
		methods: {
			next(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						EventBus.$emit('user-health-part1', this.form);
					} else {
						this.$notify({
							title: 'Rellene los campos correctamente',
							type: 'warning'
						});
					}
				});
			}

		}
	}
</script>