<template>
	<div>
		<el-form ref="form" :rules="rules" :model="form" label-position="top">
			<div class="row">
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="Enfermedades crónicas" prop="health_question_8">
						<el-radio-group v-model="form.health_question_8">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>
						<template v-if="form.health_question_8 === true">
							<el-input type="textarea" v-model="form.health_text_8"></el-input>
						</template>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="Mareos, desequilibrios" prop="health_question_9">
						<el-radio-group v-model="form.health_question_9">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>
						<template v-if="form.health_question_9 === true">
							<el-input type="textarea" v-model="form.health_text_9"></el-input>
						</template>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="Embarazo en la actualidad o en los últimos 3 meses" prop="health_question_10">
						<el-radio-group v-model="form.health_question_10">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>

						<template v-if="form.health_question_10 === true">
							<el-input type="textarea" v-model="form.health_text_10"></el-input>
						</template>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="¿Está tomando en este momento algún tipo de medicación?" prop="health_question_11">
						<el-radio-group v-model="form.health_question_11">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>

						<template v-if="form.health_question_11 === true">
							<el-input type="textarea" v-model="form.health_text_11"></el-input>
						</template>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="Ha recibido alguna recomendación médica sobre la realización de act. fís." prop="health_question_12">
						<el-radio-group v-model="form.health_question_12">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>

						<template v-if="form.health_question_12 === true">
							<el-input type="textarea" v-model="form.health_text_12"></el-input>
						</template>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="Horas de sueño habituales" prop="sleep_time">
						<el-input type="number" v-model="form.sleep_time"></el-input>
					</el-form-item>
				</div>
			</div>
		</el-form>

		<div class="row justify-content-center pt-4 mt-4 border-top">
			<div class="col-10 col-md-6 col-lg-4">
				<el-button class="btn-block" @click="next('form')" type="info" plain>Next step</el-button>
			</div>
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				form: {
					health_question_8:'',
					health_text_8:'',
					health_question_9:'',
					health_text_9:'',
					health_question_10:'',
					health_text_10:'',
					health_question_11:'',
					health_text_11:'',
					health_question_12:'',
					health_text_12:'',
					sleep_time:'',
				},
				rules: {
					health_question_8: [
						{ required: true, message: 'Campo requerido', trigger: 'change' },
					],
					health_question_9: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					],
					health_question_10: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					],
					health_question_11: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					],
					health_question_12: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					],
					sleep_time: [
						{ type:'number', required: true, message: 'Campo requerido tipo numerico', trigger: 'blur' }
					]
				}
			}
		},
		watch: {
			'form.sleep_time':function() {
				this.form.sleep_time = parseInt(this.form.sleep_time);
			}
		},
		methods: {
			next(formName) {
			    console.log('mierda')
				this.$refs[formName].validate((valid) => {
					if (valid) {
						EventBus.$emit('user-health-part2', this.form);
					} else {
						this.$notify({
							title: 'Rellene los campos correctamente',
							type: 'warning'
						});
					}
				});
			}
		}
	}
</script>