<template>
	<div>
		<div class="row">
			<div class="col-12">
				<p class="text-justify">BENEFICIOS: Se ha demostrado que los programas de entrenamiento desarrollados por Diana Serena y su equipo, producen cambios positivos en muchos sistemas y órganos de nuestro cuerpo. Estos cambios incluyen un aumento de la capacidad de trabajo, una mejora de la eficacia cardiovascular y un incremento en la fuerza muscular, de la flexibilidad, de la potencia y la resistencia.</p>
				<p class="text-justify">RIESGOS: con el envío de este formulario declaro que he comprendido perfectamente que hacer ejercicio lleva asociado un cierto riesgo para el sistema músculoesquelético (torceduras, esguinces… etc.) así como para el sistema cardio – respiratorio (mareos, molestias al respirar y problemas cardiacos). Por esta razón certifico que soy consciente de ello y declaro que no sufro de ningún problema médico (excepto los indicados anteriormente) que puedan incrementar el riesgo de sufrir una enfermedad o lesión como resultado de mi participación en un programa regular de ejercicio físico.</p>
				<p class="text-justify">CONSENTIMIENTO: con el envío de este formulario, declaro que los datos aportados son verídicos, y asumo las posibles responsabilidades legales que pudieran derivarse de cualquier omisión o incorrección en los mismos, así como de cualquier modificación sustancial que se produzca de aquí en adelante y que no haya sido comunicada en tiempo y forma.</p>
			</div>
			<div class="col-12 border-top border-bottom py-3">
				<el-checkbox-group v-model="form.conditions">
					<el-checkbox :label="true" name="type">
						Con la activación de esta casilla declaro ser mayor de 18 años y acepto la política de privacidad y las condiciones de uso de esta página web (www.dianaserena.com)
					</el-checkbox>
				</el-checkbox-group>
			</div>
			<div class="col-12 py-3">
				<p class="mb-1">Nombre: {{this.name}}</p>
				<p class="mb-1">Fecha: {{this.date}}</p>
			</div>
		</div>

		<div class="row justify-content-center pt-4 mt-4 border-top">
			<div class="col-10 col-md-6 col-lg-4">
				<el-button class="btn-block" @click="activation()" type="success" plain>Enviar</el-button>
			</div>
		</div>
			
	</div>
</template>
<script>
	export default {
		props:['name', 'date'],
		data() {
			return {
				form: {
					conditions:''
				}
			}
		},
		methods: {
			activation() {
				if(this.form.conditions) {
					EventBus.$emit('user-conditions', this.form);
				}else{
					this.$notify.error({
						title: 'Error',
						message: 'Por favor acepte las condiciones para continuar.'
					});
				}
			}
		}
	}
</script>