<template>
	<div>
		<el-form ref="form" :rules="rules" :model="form" label-position="top">
			<div class="row">
				<div class="col-12 col-md-12 col-lg-4 col-xl-6 col-form">
					<el-form-item label="Profesión" prop="profession">
						<el-input v-model="form.profession"></el-input>
					</el-form-item>
				</div>
				<div class="col-12 col-md-4 col-lg-auto col-form">
					<el-form-item label="Fumador" prop="smoke">
						<el-radio-group v-model="form.smoke">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>
					</el-form-item>
				</div>
				<div class="col-12 col-md-4 col-lg-auto col-form">
					<el-form-item label="Actividad física diaria" prop="physical_activity">
						<el-radio-group v-model="form.physical_activity">
							<el-radio :label="1">Alta</el-radio>
							<el-radio :label="2">Media</el-radio>
							<el-radio :label="3">Baja</el-radio>
						</el-radio-group>
					</el-form-item>
				</div>
				<div class="col-12 col-md-4 col-lg-auto col-form">
					<el-form-item label="Sobrepeso" prop="overweight">
						<el-radio-group v-model="form.overweight">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-form">
					<el-form-item label="Disponibilidad horaria y frecuencia con la que puede realizar 30 - 45 minutos de ejercicio" prop="time_availability">
						<el-radio-group v-model="form.time_availability">
							<el-radio :label="1">2 veces por semana</el-radio>
							<el-radio :label="2">3 veces por semana</el-radio>
							<el-radio :label="3">5 veces por semana</el-radio>
						</el-radio-group>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-form">
					<el-form-item label="¿Está dispuesta/o a implementar en su dieta unas nuevas pautas nutricionales?" prop="diet">
						<el-radio-group v-model="form.diet">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>
					</el-form-item>
				</div>
			</div>
		</el-form>

		<div class="row justify-content-center pt-4 mt-4 border-top">
			<div class="col-10 col-md-6 col-lg-4">
				<el-button class="btn-block" @click="next('form')" type="info" plain>Next step</el-button>
			</div>
		</div>


	</div>
</template>
<script>
	
	export default {
		data() {
			return {
				form: {
					smoke:'',
					profession:'',
					physical_activity:'',
					overweight:'',
					time_availability:'',
					diet:'',
				},
				rules: {
					smoke: [
						{ required: true, message: 'Campo requerido', trigger: 'change' },
					],
					profession: [
						{ required: true, message: 'Campo requerido', trigger: 'blur' }
					],
					physical_activity: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					],
					overweight: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					],
					time_availability: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					],
					diet: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					]
				}
			}
		},
		methods: {
			next(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						EventBus.$emit('user-activity', this.form);
					} else {
						this.$notify({
							title: 'Rellene los campos correctamente',
							type: 'warning'
						});
					}
				});
			}
		}
	}

</script>