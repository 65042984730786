<template>
	<div>
		<div class="card-header bg-white">
			<el-steps :active="active" finish-status="success">
				<el-step title="Step 1"></el-step>
				<el-step title="Step 2"></el-step>
				<el-step title="Step 3"></el-step>
				<el-step title="Step 4"></el-step>
				<el-step title="Step 5"></el-step>
				<el-step title="Step 6"></el-step>
			</el-steps>
		</div>
		<div class="card-body">
			<template v-if="active === 0">
				<user-data></user-data>
			</template>
			<template v-if="active === 1">
				<user-activity></user-activity>
			</template>
			<template v-if="active === 2">
				<user-health-part1></user-health-part1>
			</template>
			<template v-if="active === 3">
				<user-health-part2></user-health-part2>
			</template>
			<template v-if="active === 4">
				<user-sport></user-sport>
			</template>
			<template v-if="active === 5">
				<user-nutrition></user-nutrition>
			</template>
			<template v-if="active === 6">
				<user-conditions :name=form.name :date=form.date_form></user-conditions>
			</template>	
		</div>
	</div>
</template>
<script>

	export default {
		name:'initial-form',
		props:['user_id'],
		data() {
			return {
				active:0,
				form: {
					user_id:this.user_id,
				}
			}
		},
		methods: {
			addForm() {
				axios.post('addForm', this.form)
				.then(res => {
					this.$notify({
						title: 'Finalizado',
						message: 'Cuestionario finalizado satisfactoriamente',
						type: 'success'
					});
                    setTimeout(function(){ window.location = '/home'; }, 3000);

				})
				.catch(e => {
					console.log(e.response);
				})
			},
		},
		created() {
            EventBus.$on('user-data', (form) => {
            	this.form = Object.assign(this.form, form);
				this.active++;
            })
            EventBus.$on('user-activity', (form) => {
            	this.form = Object.assign(this.form, form);
				this.active++;
            })
            EventBus.$on('user-health-part1', (form) => {
            	this.form = Object.assign(this.form, form);

				this.active++;
            })
            EventBus.$on('user-health-part2', (form) => {
            	this.form = Object.assign(this.form, form);
				this.active++;
            })
            EventBus.$on('user-sport', (form) => {
            	this.form = Object.assign(this.form, form);
				this.active++;
            })
            EventBus.$on('user-nutrition', (form) => {
            	this.form = Object.assign(this.form, form);
				this.active++;
            })
            EventBus.$on('user-conditions', (form) => {
            	this.form = Object.assign(this.form, form);
            	if(this.form.conditions) this.addForm();
            })
		}
	}
</script>