<template>
	<div>
		<el-form ref="form" :rules="rules" :model="form" label-position="top">
			<div class="row">
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="¿Ha practicado anteriormente algún tipo de deporte o act. fís.?" prop="sport_question_1">
						<el-radio-group v-model="form.sport_question_1">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>

						<template v-if="form.sport_question_1 === true">
							<el-input type="textarea" v-model="form.sport_text_1"></el-input>
						</template>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="¿Practicas actualmente algún deporte o act. fís. Y a qué nivel?" prop="sport_question_2">
						<el-radio-group v-model="form.sport_question_2">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>

						<template v-if="form.sport_question_2 === true">
							<el-input type="textarea" v-model="form.sport_text_2"></el-input>
						</template>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="¿Has entrenado alguna vez en un centro de fitness?" prop="sport_question_3">
						<el-radio-group v-model="form.sport_question_3">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>

						<template v-if="form.sport_question_3 === true">
							<el-input type="textarea" v-model="form.sport_text_3"></el-input>
						</template>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-4 col-xl-3 col-form">
					<el-form-item label="¿Le cuesta ser constante en la asistencia a un centro de fitness?" prop="sport_question_4">
						<el-radio-group v-model="form.sport_question_4">
							<el-radio :label="true">Si</el-radio>
							<el-radio :label="false">No</el-radio>
						</el-radio-group>

						<template v-if="form.sport_question_4 === true">
							<el-input type="textarea" v-model="form.sport_text_4"></el-input>
						</template>
					</el-form-item>
				</div>
				<div class="col-12">
					<el-form-item label="Objetivos Perseguidos. (Máximo dos respuestas)" prop="objectives">
						<el-checkbox-group v-model="form.objectives">
							<el-checkbox :label="1" name="type">Beneficio Cardiovascular</el-checkbox>
							<el-checkbox :label="2" name="type">Beneficio Cardiovascular y pérdida de grasa corporal</el-checkbox>
							<el-checkbox :label="3" name="type">Beneficio Cardiovascular y aumento de masa muscular</el-checkbox>
							<el-checkbox :label="4" name="type">Beneficios de la actividad física en la mejora de  lesiones crónicas</el-checkbox>
							<el-checkbox :label="5" name="type">Beneficios de la actividad física para la tercera edad</el-checkbox>
							<el-checkbox :label="6" name="type">Beneficios de la actividad física durante el embarazo</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
				</div>
			</div>    
		</el-form>

		<div class="row justify-content-center pt-4 mt-4 border-top">
			<div class="col-10 col-md-6 col-lg-4">
				<el-button class="btn-block" @click="next('form')" type="info" plain>Next step</el-button>
			</div>
		</div>

	</div>
</template>
<script>
	
	export default {
		data() {
			return {
				form: {

					sport_question_1:'',
					sport_text_1:'',
					sport_question_2:'',
					sport_text_2:'',
					sport_question_3:'',
					sport_text_3:'',
					sport_question_4:'',
					sport_text_4:'',
					objectives:[],
				},
				rules: {
					sport_question_1: [
						{ required: true, message: 'Campo requerido', trigger: 'change' },
					],
					sport_question_2: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					],
					sport_question_3: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					],
					sport_question_4: [
						{ required: true, message: 'Campo requerido', trigger: 'change' }
					],
					objectives: [
						{ type: 'array', required: true, message: 'Campo requerido', trigger: 'change' }
					]
				}
			}
		},
		methods: {
			next(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						EventBus.$emit('user-sport', this.form);
					} else {
						this.$notify({
							title: 'Rellene los campos correctamente',
							type: 'warning'
						});
					}
				});
			}

		}
	}

</script>