<template>
	<div>
		<el-form ref="form" :rules="rules" :model="form"label-position="top">
			<div class="row">
				<div class="col-12 col-md-6 col-lg-6">
					<el-form-item label="Donde come ( en casa, fuera)" prop="nutrition_question_1">
						<el-input type="textarea" v-model="form.nutrition_question_1"></el-input>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-6">
					<el-form-item label="Nº de comidas día e intervalo" prop="nutrition_question_2">
						<el-input type="textarea" v-model="form.nutrition_question_2"></el-input>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-6">
					<el-form-item label="Agua, día (litros)" prop="nutrition_question_3">
						<el-input type="textarea" v-model="form.nutrition_question_3"></el-input>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-6">
					<el-form-item label="Otras bebidas" prop="nutrition_question_4">
						<el-input type="textarea" v-model="form.nutrition_question_4"></el-input>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-6">
					<el-form-item label="Alimentos mas consumidos" prop="nutrition_question_5">
						<el-input type="textarea" v-model="form.nutrition_question_5"></el-input>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-6">
					<el-form-item label="Menos consumidos" prop="nutrition_question_6">
						<el-input type="textarea" v-model="form.nutrition_question_6"></el-input>
					</el-form-item>
				</div>
				<div class="col-12 col-md-6 col-lg-6">
					<el-form-item label="Malos hábitos y caprichos que me gustaría destacar">
						<el-input type="textarea" v-model="form.nutrition_question_7"></el-input>
					</el-form-item>
				</div>
			</div>
		</el-form>

		<div class="row justify-content-center pt-4 mt-4 border-top">
			<div class="col-10 col-md-6 col-lg-4">
				<el-button class="btn-block" @click="next('form')" type="info" plain>Next step</el-button>
			</div>
		</div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
				form: {
					nutrition_question_1:'',
					nutrition_question_2:'',
					nutrition_question_3:'',
					nutrition_question_4:'',
					nutrition_question_5:'',
					nutrition_question_6:'',
					nutrition_question_7:'',
				},
				rules: {
					nutrition_question_1: [
						{ required: true, message: 'Campo requerido', trigger: 'blur' },
					],
					nutrition_question_2: [
						{ required: true, message: 'Campo requerido', trigger: 'blur' }
					],
					nutrition_question_3: [
						{ required: true, message: 'Campo requerido', trigger: 'blur' }
					],
					nutrition_question_4: [
						{ required: true, message: 'Campo requerido', trigger: 'blur' }
					],
					nutrition_question_5: [
						{ required: true, message: 'Campo requerido', trigger: 'blur' }
					],
					nutrition_question_6: [
						{ required: true, message: 'Campo requerido', trigger: 'blur' }
					]
				}
			}
		},
		methods: {
			next(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						EventBus.$emit('user-nutrition', this.form);
					} else {
						this.$notify({
							title: 'Rellene los campos correctamente',
							type: 'warning'
						});
					}
				});
			}

		}
	}
</script>